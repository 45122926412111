<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <List
      resource="allUnits"
      :fields="fields"
      ref="list_customer"
      :pageSize="18"
      trackBy="unit_name"
      :search="true"
      :clickable="isSync"
      :key="isUpdated"
      :isCommunityRequired="true"
      @deleteRow="deleteUnit"
      @rowData="hanleEditRow"
      :requestParams="{sortField: '', direction: ''}"
    >
      <template v-slot:message>
        <div class="font-semibold font-inter bg-blue-50 text-blue-700">
          {{loading ? '' :
              isSync
              ? `You are not connected to one of the available systems to manage unit availability and pricing automatically. Therefore, please use this to ensure that the Digital Human has an updated list for your community`
              : `Your availability and pricing is being managed automatically. Therefore, this list is information only. To edit this list, you'll need to update your source CRM or Property Management System.`
          }}
        </div>
      </template>
      <template  v-slot:status>
        <icon name="trash" class="h-6 w-6 inline ml-3"></icon>
      </template>
    </List>
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import Loader from '@/components/ui/Loader'
import List from '@/components/digital_human/List'
import Icon from "@/components/ui/Icon";
import EventBus from '@/utils/EventBus';
import { mapActions, mapGetters } from 'vuex'
export default {
  mixins: [NotifyMixin, AuthMixin, ModalNavigation],
  components: {
    Loader,
    List,
    Icon
  },
  data() {
    return {
      unitId: '',
      loading: false,
      isUpdated: false,
      isDisabledProp: false,
      isSync: true,
      footer: {
          primaryButton: 'save',
          tertiaryButton: 'cancel'
      },
      uploadedFile: null,
       fields: [
        {
          name: 'floor_plan_image_link',
          title: 'floorplan',
          formatter: (value) => {
            return this.getFloorplanImage(value)
          },
        },
        {
          name: 'unit_name',
          title: 'unit',
          sortField: 'unit_name',
          highlight: true,
        },
        {
          name: 'floor_plan_type',
          title: 'unit type',
          sortField: 'floor_plan_type',
          highlight: true,
        },
        {
          name: 'floor_plan_name',
          title: 'floorplan name',
          sortField: 'floor_plan_name',
          highlight: true
        },
        {
          name: 'size_in_square_feet',
          title: 'sqft',
          sortField: 'size_in_square_feet',
        },
        {
          name: 'floor_plan_bedroom_count',
          title: 'bed',
          sortField: 'floor_plan_bedroom_count'
        },
        {
          name: 'floor_plan_full_bath_count',
          title: 'bath',
          sortField: 'floor_plan_full_bath_count'
        },
        {
          name: 'starting_price',
          title: 'starting price',
          sortField: 'starting_price',
          formatter: (value) => {
            return value.toLowerCase() == '$nan' ? 0 : value
          },
        },
        {
          name: 'available_to_lease',
          title: 'available',
          sortField: 'available_to_lease',
          formatter: (value) => {
            return this.getIconAvailability(value)
          },
        },
        {  
          name: 'status',  
          title: 'action'
        }
      ],
    }
  },
  mounted() {
    if (!this.community)
      this.notifyError('please select a community to continue, then refresh the browser');
    else
      this.syncUnits()
  },
  watch: {
    community: function () {
      if (this.community?.id )
        this.syncUnits()
      else
        this.notifyError('please select a community to continue, then refresh the browser');
    }
  },
  computed: {
    ...mapGetters({
      getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl'
    })
  },
  methods:{
    ...mapActions({
      setUnitId: 'floorplan/setUnitId',
      setFloorplan: 'floorplan/setFloorplan',
      removeActionFromStore: 'actions/removeAction',
      resetActions: 'actions/resetActions',
    }),
    deleteUnit(row) {
      this.unitId = row['data'].id_unit
      this.isDisabledProp = true;
      EventBus.emit('show-modal-confirmation', {
        id: 'dh-manage-availability-and-pricing',
        title: 'unit availability and pricing',
        showConfirmation: true,
        confirmationMessage: `Are you sure you want to delete the ${row['data'].unit_name} floorplan unit ?`,
        confirmationType: 'warning',
      })
      EventBus.once('confirm-dh-manage-availability-and-pricing', () => {
        this.isDisabledProp = false;
        this.onDelete()
      })
      EventBus.on('cancel-confirmation-dh-manage-availability-and-pricing', () => {
        this.isDisabledProp = false;
        this.cancel()
      })
    },
    hanleEditRow(row) {
      if (this.isDisabledProp) return;
      this.unitId = row['data'].id_unit
      this.setUnitId(this.unitId)
      if (this.unitId) {
        const unitId = this.unitId;
        this.loading = true;
        this.$dhDataProvider.get('editUnit', {id: unitId})
        
        .then(response => {
          const sp = response.starting_price.toString().includes('$') ? response.starting_price.toString().slice(1) : response.starting_price;
          const uuid = response.floor_plan_image_link && response.floor_plan_image_link !== null &&
            response.floor_plan_image_link !== '' ? this.getFileIdByThumbnailUrl(response.floor_plan_image_link) : undefined;
          const uuids = [];
          uuid ? uuids.push(uuid) : [];
          this.setFloorplan({
            ...response,
            starting_price: Number(sp),
            floor_plan_image_link: uuids
          })
          this.loading = false;
          this.$router.push({name: 'availability_pricing.edit', params: {id: this.unitId}});
        })
        .catch((error) => (this.notifyError(error), this.setFloorplan({})));
        }
    },
    onDelete(){
      this.loading = true
      this.$dhDataProvider
        .delete('unit', { id: this.unitId })
        .then(() => {
          this.notifySuccess('the floorplan unit has been deleted correctly')
          this.isUpdated = true
        })
        .catch((err) => this.notifyError(err.message))
        .finally(() => {
          this.unitId = ''
          this.isUpdated = false
          this.loading = false
        })
    },
    getFloorplanImage(value) {
      if(value) {
        return `<img class="w-20 h-12 m-auto" src=${value} />`
      } else {
        return `<img class="w-20 h-12 m-auto" src=${require('@/assets/modules/NA.png')} />`;
      }
    },
    syncUnits(){
        this.resetActions();
        this.notifySuccess('your availability list is being pulled from externalAPI.')
        this.isSync = true
        this.loading = true
        this.$dhDataProvider.create('syncUnits', {data: {
          community_id: this.community?.id,
          customer_id: this.profile?.customerId,
        }})
        .then((data) => {
          if(data.status === 200)
          {
            this.isUpdated = true
            this.isSync = !data.sync
            if(data.sync) {
              this.removeActionFromStore({title: 'add available unit', routeName: 'availability_pricing.index'})
              this.removeActionFromStore({title: 'import availability list', routeName: 'availability_pricing.index'})
            }
            this.notifySuccess('units created successfully.')
          }
        })
        .catch((error) => (
          this.notifyError(error.message),
          this.loading = false))
        .finally(() => {
          this.isUpdated = false
          this.loading = false
        })
    },
    cancel(){
      this.unitId = '';
    },
    getIconAvailability(value) {
      return value ? `<img class="w-7 h-5 m-auto" src=${require('@/assets/modules/check.svg')} />` : `<img class="w-5 h-5 m-auto" src=${require('@/assets/modules/x.svg')} />`
    },
  }
  
}
</script>